import  {Component} from 'react';
import api from '../api';
import {ToastStore} from 'react-toasts';

var moment = require('moment');
const TIPO_CHAMADA_POSICOES = 0;
const TIPO_CHAMADA_TRECHO_POSICOES = 1;
const TIPO_CHAMADA_POSICOES_EVENTOS_GRID = 2;


export class GetEventos extends Component{
    constructor(props, mapaKseg, posicoesVeiculo, tipo_chamada, veiculo_id, RelPosicoesVeiModal, GetPosicoes){
        super(props);
        this.state = {
            evento: {},
            list: [],
            loading:true,
            mapaKseg:mapaKseg,
            posicoesVeiculo: posicoesVeiculo,
            markerPosicao_list:[],
            tipo_chamada: tipo_chamada,
            veiculo_id: veiculo_id,
            RelPosicoesVeiModal: RelPosicoesVeiModal,
            eventos_count:[],
            GetPosicoes: GetPosicoes,
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };   
    //--------------------------------  
    setDiaSemana = (dia) => {
        switch(dia) {
            case 'seg':
              return 'segunda-feira';
            case 'ter':
              return 'terça-feira';
            case 'qua':
                return 'quarta-feira';  
            case 'qui':
                return 'quinta-feira';    
            case 'sex':
              return 'sexta-feira';
            case 'sáb':
                return 'sábado';  
            case 'dom':
                return 'domingo';    
            default:
                return ''  ;
        }
    };
    //---------------------
    setDiaMes = (data) => {
        var dia_mes = data.split("-");

        switch(parseInt(dia_mes[1])) {
            case 1:
              return dia_mes[0]+' de janeiro';
            case 2:
              return dia_mes[0]+' de fevereiro';
            case 3:
                return dia_mes[0]+' de março';  
            case 4:
                return dia_mes[0]+' de abril';    
            case 5:
              return dia_mes[0]+' de maio';
            case 6:
                return dia_mes[0]+' de junho';  
            case 7:
                return dia_mes[0]+' de julho';    
            case 8:
                return dia_mes[0]+' de agosto';
            case 9:
                return dia_mes[0]+' de setembro';                                    
            case 10:
                return dia_mes[0]+' de outubro';                
            case 11:
                return dia_mes[0]+' de novembro';                
            case 12:
                return dia_mes[0]+' de dezembro';                
            default:
                return parseInt(dia_mes[1])  ;
        }
    };
    //---------------------
    setDiaMesHora = (data) => {
        var dia_mes = data.split("-");

        if(dia_mes[2]===null)
            return null;

        return this.setDiaMes(data) + " "+dia_mes[2];
    };
    //---------------------
    setIconeMarker = (tipo_evento_id) => {       
        switch(tipo_evento_id) {                                      

            case 66:
                return {url: require("../img/alert_octagon2.png")
                        , anchor: null
                        ,scaledSize: new this.state.mapaKseg.props.google.maps.Size(30,40)}
                // return require("./img/alert_octagon2.png");                              
            case 67:
                return {url: require("../img/point_position_green20.png")
                        , anchor: new this.state.mapaKseg.props.google.maps.Point(10,10)}
            case 68:
                return {url: require("../img/marker_porta_aberta_23.png")
                        , anchor: null
                        ,scaledSize: new this.state.mapaKseg.props.google.maps.Size(45,45)}
            case 69:
                return {url: require("../img/marker_porta_lateral_aberta2.png") 
                        , anchor: null
                        ,scaledSize: new this.state.mapaKseg.props.google.maps.Size(45,45)}     
            case 70:
                return {url: require("../img/alert_octagon2.png") 
                       , anchor: null
                       ,scaledSize: new this.state.mapaKseg.props.google.maps.Size(28,40)}      
            case 74:
            return {url: require("../img/marker_truck_open.png") 
                    , anchor: null
                    ,scaledSize: new this.state.mapaKseg.props.google.maps.Size(45,45)}                                      
            // case 69:
            //     return {url: require("../img/alert_octagon2.png")
            //             , anchor: null
            //             ,scaledSize: new this.state.mapaKseg.props.google.maps.Size(30,40)}
            default:
                return {url: require("../img/point_position_red2.png"), anchor: new this.state.mapaKseg.props.google.maps.Point(10,10)}
            //   return require("./img/point_position_red2.png");  
          }
    };    
    //---------------------
    setIcone = (tipo_evento_id) =>{    
        switch(tipo_evento_id) {
            case 1:
            return require("../img/location_marker48.png"); 
            case 3:
                return require("../img/ic_marker_bloqueio.png");                                           
            case 42:
                return require("../img/ic_antenna_black_48.png"); 
            case 66:
                return require("../img/key_off.png");                              
            case 67:
                return require("../img/card_green48.png"); 
            case 68:
                return require("../img/marker_porta_aberta36.png");  
            case 69:
                return require("../img/marker_porta_lateral_aberta2.png");  
            case 70:
                return require("../img/alert_octagon24x24.png");                     
            case 71:
                return require("../img/ic_antenna_black_48.png");
            case 74:
                return require("../img/truck_open_48.png");                
            default:
            return require("../img/alert_circle48.png");  
        }
    };
    //--------------------
    dateDiff=(data_inicio, data_final)=>{
        // data_inicio:  2020-11-26 16:44:32

        if(String(data_inicio).length> 19){
            var ano_anterior = data_inicio.substring(0,4);
            var mes_anterior = data_inicio.substring(5,7);
            var dia_anterior = data_inicio.substring(8,10);
            var hora_anterior = data_inicio.substring(12,20);
            // console.log("*********"+ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior)
            var a = moment(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");  //2020-03-17T17:39:31-03:00
    
            var ano = data_final.substring(0,4);
            var mes = data_final.substring(5,7);
            var dia = data_final.substring(8,10);
            var hora = data_final.substring(12,20);
            var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00
            const precise_diff =  moment.preciseDiff(a, b, true); 
        
            const diff = b.diff(a);
            const diffDuration = moment.duration(diff);
            const diff_minutes = parseInt(diffDuration.asMinutes())
            // const diff_days = parseInt(diffDuration.asDays())
        
            // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
            // alert(diff_minutes + " minutes");
        
            // console.log("****  diff_minutes: "+String(diff_minutes));
        
            // return String(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");
            // return "("+String(diff_minutes)+"min)";
        
            if(precise_diff.minutes===0)
                if(precise_diff.seconds===0)
                    return "0";
                else return precise_diff.seconds+"seg";
            else if(precise_diff.hours === 0)
                return precise_diff.minutes+"min";
            else if(precise_diff.days === 0){
                if(precise_diff.hours===1)
                return String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }else if(precise_diff.months === 0){
                if(precise_diff.days===1)
                return String(precise_diff.days)+"dia "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.days)+"dias "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
            }else if(precise_diff.years === 0){
                if(precise_diff.months===1)
                return String(precise_diff.months)+"mes "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
                else return String(precise_diff.months)+"meses "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }
        }
    }
    //--------------------
    formatarDateTimeGrid(dtTime){
        //  sex 21:47:10   03-07-2020  
        if(dtTime===null)
           return "";
        var datetime = "";
       
        if(this.props.data_receb){
            if(String(this.props.data_receb).length > 24){
                let y = this.props.data_receb.substring(21,25);
                let mm = this.props.data_receb.substring(18,20);
                let dd = this.props.data_receb.substring(15,17);
                let hm = this.props.data_receb.substring(3,13);    
                datetime = " "+dd+"/"+mm+"/"+y+"   "+hm;  // "Y-MM-DD H:M"
            }
        }
       return datetime; 
    }
    //------------------------
    getEventos = async () => {  
        let eventos_count = [];
        let datetime ="";
        try {    
            if(String(this.props.data_receb).length > 20){
                let y = this.props.data_receb.substring(21,25);
                let mm = this.props.data_receb.substring(18,20);
                let dd = this.props.data_receb.substring(15,17);
                let hm = this.props.data_receb.substring(3,9);
                
                
                datetime = moment(y+"-"+mm+"-"+dd+" "+hm+"-03:00").format('Y-MM-DD HH:mm');
            }

            let body = new FormData()
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_final', String(datetime))
            body.append('token', localStorage.getItem('token'))

            var listString = "[";
            var posicoesVeiculo = this.state.posicoesVeiculo;

            // console.log("  .recebimento_id:");
            for(var i=0; i<posicoesVeiculo.length; i++ ){
                // console.log(" posicoesVeiculo[i]: ");
                // console.log(posicoesVeiculo[i]);
                if( posicoesVeiculo[i] != undefined){
                    if(i != posicoesVeiculo.length-1)
                        listString = listString + posicoesVeiculo[i].recebimento_id + ",";
                    else listString = listString + posicoesVeiculo[i].recebimento_id + "]"; 
                }
            }

            body.append('recebimento_id_json', listString)
            // console.log("  ***datetime: "+datetime)

            const response = await api.post('/evento_api/evento', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                
                let evento = this.state.evento;
                let list = this.state.list;    
                if(resposta.length>0)                
                    eventos_count.push({recebimento_id: resposta[0].recebimento_id, count:0, descricao: "", index: ""});
            

                // ToastStore.success(" Eventos length: "+ String(resposta.length));
                // console.log("  ***Eventos length: "+String(resposta.length))
                for (var i = 0; i < resposta.length; i++) {                        
                    evento = {    
                        descricao: resposta[i].descricao,
                        tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                        recebimento_id: resposta[i].recebimento_id,
                        tipo_evento_id: resposta[i].tipo_evento_id,
                        data_receb: resposta[i].data_receb,
                        data_inicio: resposta[i].data_inicio,
                        data_final: resposta[i].data_final,                        
                        evento_unico: resposta[i].evento_unico,
                        valor: resposta[i].valor,
                        nome: resposta[i].nome,
                        velocidade: resposta[i].velocidade,

                        latitude: resposta[i].latitude,
                        longitude: resposta[i].longitude,      

                        icone: null,
                        dia_semana: null,
                        hora: null,
                        dia_mes: null,

                        data_inicio2: null,
                        data_final2: null,
                        icone_marker: null,     
                        visible_marker: true, 
                        dateDiff: "",
                        data_inicial_format: this.formatarDateTimeGrid(resposta[i].data_inicio),
                        data_final_format: this.formatarDateTimeGrid(resposta[i].data_final),
                    };
                    console.log("  descricao "+String(resposta[i].descricao)+"   data_inicio "+String(resposta[i].data_inicio));
                    // console.log("  posicao.data_inicio "+String(resposta[i].data_inicio)+"\n  posicao.data_final "+String(resposta[i].data_final));

                    if(resposta[i].data_final!==null && resposta[i].data_final!== "")
                        evento.dateDiff = String(this.dateDiff(resposta[i].data_inicio, resposta[i].data_final))
                    else evento.dateDiff = ""

                    // ToastStore.success(" evento.data_receb: "+String(evento.data_receb));
                    // ToastStore.success(evento.data_receb);
                    if(evento !== null){
                        if(evento.data_receb !== undefined)
                            evento.hora = evento.data_receb.substring(4,9); 
                    }                            


                    evento.icone = this.setIcone(evento.tipo_evento_id);
                    evento.icone_marker = this.setIconeMarker(evento.tipo_evento_id);


                    if(evento.data_receb !== "" && evento.data_receb !== undefined)
                        evento.dia_semana = this.setDiaSemana(evento.data_receb.substring(0,3));
                    
                    if(evento.data_receb !== "" && evento.data_receb !== undefined)
                        evento.dia_mes = this.setDiaMes(evento.data_receb.substring(13,26));

                    if(evento.data_inicio !== "" && evento.data_inicio !== undefined)
                        evento.data_inicio2 = this.setDiaMesHora(evento.data_inicio.substring(8,10) +'-'+ evento.data_inicio.substring(5,7) +'-'+ evento.data_inicio.substring(11,17));

                    if(String(evento.data_final) !== "" && evento.data_final !== undefined)
                        evento.data_final2 = this.setDiaMesHora(resposta[i].data_final.substring(8,10) +'-'+ resposta[i].data_final.substring(5,7) +'-'+ evento.data_final.substring(11,17)); 


                    for (var j=0; j< eventos_count.length; j++) {
                        if(eventos_count[j].recebimento_id === evento.recebimento_id){
                            eventos_count[j].count = eventos_count[j].count+1;
                            eventos_count[j].descricao = eventos_count[j].descricao+", "+evento.descricao;
                            eventos_count[j].index = eventos_count[j].index +", "+String(i);
                            break;
                        }
                        if(j === eventos_count.length-1){
                            eventos_count.push({recebimento_id: evento.recebimento_id, count:1, descricao: evento.descricao, index: String(i)});
                            break;
                        }
                    }

                    list.push(evento);
                    this.setState({list: list});

                    if(evento.tipo_evento_id===68){        
                        // for(var i=0; i<this.state.mapaKseg.state.markerPosicao_list.length; i++){
                        //     if(this.state.mapaKseg.state.markerPosicao_list[i].recebimento_id===evento.recebimento_id)
                        //         this.state.mapaKseg.state.markerPosicao_list[i].tipo_evento_id = 68;
                        // }
                    }
                }

                this.setState({loading: false});
                
            }else{
            }

        } finally {
            // ToastStore.success("GetPo coords.length: "+ String(coords.length));
            // this.state.mapaKseg.setState({comandos_list: this.state.list});


            var list_novo = [];
            var achou=false;
            //----1º se nao houver duplicados (entre list e posicoesVeiculo_list), entao da um push no list_novo
            for (var i = 0; i < this.state.list.length; i++) {
                achou = false;
                for (var j = 0; j < this.state.mapaKseg.state.eventos_list.length; j++) {
                if (this.state.list[i].latitude == this.state.mapaKseg.state.eventos_list[j].latitude)
                    if (this.state.list[i].longitude == this.state.mapaKseg.state.eventos_list[j].longitude)
                    if(this.state.list[i].data_inicio == this.state.mapaKseg.state.eventos_list[j].data_inicio){
                        achou = true;
                        break;
                    }
                }
                if(achou===false){
                    list_novo.push(this.state.list[i]);
                }
            }

            //-------------------
            for (var i=0; i<list_novo.length; i++) {
                if(i !== list_novo.length-1){
                    if(list_novo[i].recebimento_id === list_novo[i+1].recebimento_id){
                        // this.state.mapaKseg.callToast("Achou!! "+String(list_novo[i].recebimento_id));
                        if(list_novo[i].tipo_evento_id === list_novo[i+1].tipo_evento_id){
                            // this.state.mapaKseg.callToast("Achou repetido! "+String(list_novo[i].recebimento_id)+"  evento: "+String(list_novo[i].tipo_evento_id));
                            list_novo[i].visible_marker=false;
                        }
                    }
                }
            }
            //-------------------
            for (var i=0; i<list_novo.length; i++) {
                if(i !== list_novo.length-1){
                    if(list_novo[i].visible_marker){
                        if(list_novo[i].recebimento_id === list_novo[i+1].recebimento_id){
                            for (var j=i+1; j<list_novo.length; j++) {
                                if(list_novo[i].recebimento_id === list_novo[j].recebimento_id){
                                    if(list_novo[i].tipo_evento_id === 70 && list_novo[j].tipo_evento_id===68){ // 70-parada  68-porta traseira aberta
                                        // this.state.mapaKseg.callToast("Achou! "+String(list_novo[i].recebimento_id)+"  evento: "+String(list_novo[j].tipo_evento_id));
                                        list_novo[i].visible_marker=false;
                                    }else if(list_novo[i].tipo_evento_id === 70 && list_novo[j].tipo_evento_id===69){ // 69-porta lateral aberta
                                        // this.state.mapaKseg.callToast("Achou! "+String(list_novo[i].recebimento_id)+"  evento: "+String(list_novo[j].tipo_evento_id));
                                        list_novo[i].visible_marker=false;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // console.log("eventos_count: "+String(eventos_count.length));
            // console.log("list.length: "+String(this.state.list.length));
            // console.log(eventos_count);
            //----2º join dos que ja tinha, com os novos


            if(this.state.tipo_chamada === TIPO_CHAMADA_POSICOES){
                //----2º join dos que ja tinha, com os novos
                // var joined = list_novo.concat(posicoesVeiculo_list);
                var joined = this.state.mapaKseg.state.eventos_list.concat(list_novo);

                // console.log(" eventos joined.length: "+String(joined.length));
                // for (var i=0; i<joined.length; i++) {
                //     console.log(" eventos joined["+i+"]:   receb_id: "+String(joined[i].recebimento_id)+"  tipo_evento_id: "+String(joined[i].tipo_evento_id)+"   data_receb: "+String(joined[i].data_receb) +"   latitude: "+String(joined[i].latitude)+"   longitude: "+String(joined[i].longitude)+"  visible_marker: "+String(joined[i].visible_marker)+"  desc: "+String(joined[i].descricao));  
                // }
                // console.log(" eventos joined---------------------------------");        


                // var joined = this.state.mapaKseg.state.eventos_list.concat(this.state.list);
                this.state.mapaKseg.setState({eventos_list: joined});
                this.state.mapaKseg.setState({carregandoMaisEventos: false});
                // this.state.mapaKseg.setState({markerPosicao_list: this.state.mapaKseg.state.markerPosicao_list});
                // this.state.RenderEventos.setEventos_list(this.state.list);
            }else if(this.state.tipo_chamada === TIPO_CHAMADA_TRECHO_POSICOES){
                this.state.RelPosicoesVeiModal.setState({trecho_evento_list: list_novo});
                this.state.RelPosicoesVeiModal.setEventosCount(eventos_count);

            }else if(this.state.tipo_chamada === TIPO_CHAMADA_POSICOES_EVENTOS_GRID){
                if(this.state.GetPosicoes !== null)
                    this.state.GetPosicoes.retornarList(this.state.list, this.state.GetPosicoes);
                    this.state.GetPosicoes.setEventosCount(eventos_count);
            }
        }
    };
    //-------------------------------------------------------------------------------------------------
    async componentDidMount() {
        // ToastStore.success("getPosicoesVeiculo componentDidMount()");
        this.getEventos();
    };
}